import Vue, { CreateElement, VNode } from 'vue';
import { IClsContainerMixin, ClsContainerMixin } from '../mixins/cls-container-mixin';
import { yieldToMain } from '../../assets/js/utilities/runTask';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { Fragment } from '../FragmentComponent/FragmentComponent';
import { IClsEventObject } from '../../components/mixins/cls-base-mixin';
import { ThreadTaskWrapperProps } from './ThreadTaskWrapper.props';

export default Vue.extend({
	name: 'ThreadTaskWrapper',
	mixins: [ClsContainerMixin, ClsComponentMixin],
	props: ThreadTaskWrapperProps,
	data() {
		return {
			renderContent: false,
		};
	},
	methods: {
		deferRendering() {
			this.$nextTick?.(async () => {
				await yieldToMain();
				this.renderContent = true;

				this.$nextTick(async () => {
					await yieldToMain();
				});
			});
		},
	},
	// @ts-ignore
	render(h: CreateElement): VNode {
		// Skip when not running in browser
		if (typeof window === 'undefined') {
			return h(Fragment, this.$slots.default);
		}

		// Fallback when no child component exists
		if (!this.$slots?.default) {
			return h('section');
		}

		// Execute logic only when calling page initially
		if (
			this.$nuxt &&
			(!this.$nuxt.context.from || this.$nuxt.context.from.fullPath?.includes('force-device')) &&
			!window?.location?.href?.includes('noThreadTaskWrapper')
		) {
			this.deferRendering();
		} else {
			this.renderContent = true;
		}

		if (this.renderContent) {
			this.$slots.default.forEach((_c, ix) => {
				this.$slots.default?.[ix].context?.$on('clsEvent', (clsEventObject: IClsEventObject) => {
					(this as unknown as IClsContainerMixin).handleClsEvent(clsEventObject);
				});
			});

			return h(Fragment, this.$slots.default);
		}

		return this.$slots?.default?.[0];
	},
});
