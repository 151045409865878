import { PropType } from 'vue';
import { Props } from '../../types/core';
import { IconText } from '../../types/text';

// Model -------------------------------------------------------------------------------------------

export interface UspBarModel {
	closable?: boolean;
	cookieExpires?: number;
	items: IconText[];
	animated?: boolean;
	isAnimating?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const UspBarProps: Props<UspBarModel> = {
	closable: {
		type: Boolean as PropType<UspBarModel['closable']>,
		default: true,
	},
	cookieExpires: {
		type: Number as PropType<UspBarModel['cookieExpires']>,
		default: 60,
	},
	items: {
		type: Array as PropType<UspBarModel['items']>,
		required: true,
	},
	animated: {
		type: Boolean as PropType<UspBarModel['animated']>,
		default: false,
	},
	isAnimating: {
		type: Boolean as PropType<UspBarModel['isAnimating']>,
		default: false,
	},
};
