var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ThreadTaskWrapper',[_c('Layout',{attrs:{"loading-overlay":""}},[(_vm.header)?_c('template',{slot:"header"},[_c('Header',{attrs:{"uid":_vm.headerComponentId}})],1):_vm._e(),_c('template',{slot:"main"},[_c('LayoutType',{class:{
					'not-found': _vm.is404Page,
					'tw-overflow-x-hidden': _vm.isStoreDetailPage,
				},attrs:{"center-small":!_vm.isProductPage &&
					!_vm.isProductListingPage &&
					!_vm.is404Page &&
					!_vm.isSearchNoResults &&
					!_vm.isPasswordResetPage,"center-big":_vm.isProductPage ||
					_vm.isProductListingPage ||
					_vm.is404Page ||
					_vm.isSearchNoResults ||
					_vm.isPasswordResetPage,"support-legacy-grid":_vm.isContentPage && !_vm.is404Page,"full-width":_vm.isLoginPage,"overflow-hidden":_vm.isMyAccountPage}},[_c('MessageboxWrapper'),_vm._t("default",null,{"template":true}),_vm._t("richsnippet"),_c('hydrateable-client-only',[_c('ScrollToTopButton',{attrs:{"uid":"SCROLL_TO_TOP_BUTTON"}})],1),_vm._v(" "+_vm._s('')+" ")],2)],1),(_vm.footer)?_c('template',{slot:"footer"},[_c('IntersectingComponent',[_c('LazyHydrate',{attrs:{"when-idle":!_vm.uiBlockedByOverlay,"trigger-hydration":_vm.triggerInitialHydration}},[_c('WebsiteFooter',{staticClass:"tw-mt-20",attrs:{"uid":"FOOTER"}})],1)],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }