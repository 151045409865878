import { Mixin, Vue } from '@/app-utils/decorators';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';

@Mixin
export class AbstractComponent extends Vue {
	get componentStyle(): string[] {
		return [];
	}

	get style() {
		return [...this.componentStyle, ...this.deviceStyle, ...(this.$additionalStyles || [])];
	}

	get deviceStyle() {
		const { state: mediaqueryState } = useMediaqueryStore(this.$store);

		return [
			this.$style[mediaqueryState.device.category],
			this.$style[mediaqueryState.device.orientation],
		];
	}
}
