import { PropType } from 'vue';
import { EnhancedBrand } from '../../types/product';
import { Props } from '../../types/core';
import { Link } from '../../types/link';
import { ListModel } from '../List/List.props';

// Model -------------------------------------------------------------------------------------------

export interface FlyoutBrandsModel {
	uid: string;
	parentCategory: Link;
	brandsUrl: string;
	topBrands: EnhancedBrand[];
	highlightedBrands: ListModel;
	brands: EnhancedBrand[];
}

// Props -------------------------------------------------------------------------------------------

export const FlyoutBrandsProps: Props<FlyoutBrandsModel> = {
	uid: {
		type: String as PropType<FlyoutBrandsModel['uid']>,
		required: true,
	},
	parentCategory: {
		type: Object as PropType<FlyoutBrandsModel['parentCategory']>,
		required: true,
	},
	brandsUrl: {
		type: String as PropType<FlyoutBrandsModel['brandsUrl']>,
		required: true,
	},
	topBrands: {
		type: Array as PropType<FlyoutBrandsModel['topBrands']>,
		required: true,
	},
	highlightedBrands: {
		type: Object as PropType<FlyoutBrandsModel['highlightedBrands']>,
		required: true,
	},
	brands: {
		type: Array as PropType<FlyoutBrandsModel['brands']>,
		required: true,
	},
};
