import { PropType } from 'vue';
import { Props } from '@/node_modules/@osp/design-system/types/core';

// Model -------------------------------------------------------------------------------------------

export interface FooterModel {
	uid: string;
}

// Props -------------------------------------------------------------------------------------------

export const FooterProps: Props<FooterModel> = {
	uid: {
		type: String as PropType<FooterModel['uid']>,
		required: true,
	},
};
