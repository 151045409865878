import Vue from 'vue';
import { Link } from '../../types/link';
import { getIconCode } from '../Ico/Ico';
import { ListProps } from './List.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'List',
	props: ListProps,
	data() {
		return {
			activeLinkIndex: null as number | null,
		};
	},
	computed: {
		items(): Link[] {
			if (this.links) {
				const linkIndex = this.maxAmount || this.links.length;
				const amount = this.links.length > linkIndex ? linkIndex - 1 : linkIndex;

				return this.links.slice(0, amount);
			}

			return [];
		},
		moreText(): string | undefined {
			if (!this.links) return;

			const amount = this.links.length - this.items.length;

			return this.more.text.replace('{0}', amount.toString());
		},
		icon() {
			const iconName = 'arrow-up-right-small';

			return `--icon: '${getIconCode(iconName)}'`;
		},
	},
	beforeMount() {
		this.activeLinkIndex = this.items?.findIndex(({ href }) => href === location.href);
	},
});
