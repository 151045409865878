import { Mixin, Prop, Vue, Watch } from '@/app-utils/decorators';

@Mixin
export class RegistrationEmailMixin extends Vue {
	@Prop()
	public email: string;

	@Watch('email')
	setEmailInputValue(email: string) {
		const form = (this.$refs.formWrap as Vue).$refs.form as HTMLFormElement;

		if (form) {
			form.setField('email', email, true);
		}
	}

	getEmailInputValue() {
		const form = (this.$refs.formWrap as Vue).$refs.form as HTMLFormElement;
		if (form && form.values) {
			return form.values.email;
		} else {
			return null;
		}
	}
}
