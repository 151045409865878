import { PropType } from 'vue';
import { Props } from '../../types/core';
import { ModalMode } from '../../types/modal';

// Model -------------------------------------------------------------------------------------------

export interface ModalModel {
	uid: string;
	value: boolean;
	preloadAndKeepAlive?: boolean;
	contentPadding?: boolean;
	title?: string;
	mode?: ModalMode;
	showBackArrow?: boolean;
	widthClass?: string;
	keyboardAvoidingView?: boolean;
	lockPageWhenOpened?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const ModalProps: Props<ModalModel> = {
	uid: {
		type: String as PropType<ModalModel['uid']>,
		required: true,
	},
	value: {
		type: Boolean as PropType<ModalModel['value']>,
		required: true,
	},
	preloadAndKeepAlive: {
		type: Boolean as PropType<ModalModel['preloadAndKeepAlive']>,
		default: false,
	},
	contentPadding: {
		type: Boolean as PropType<ModalModel['contentPadding']>,
		default: true,
	},
	title: {
		type: String as PropType<ModalModel['title']>,
		default: null,
	},
	mode: {
		type: String as PropType<ModalModel['mode']>,
		default: ModalMode.IS_STANDARD,
	},
	showBackArrow: {
		type: Boolean as PropType<ModalModel['showBackArrow']>,
		default: false,
	},
	widthClass: {
		type: String as PropType<ModalModel['widthClass']>,
		default: 'tw-w-144',
	},
	keyboardAvoidingView: {
		type: Boolean as PropType<ModalModel['keyboardAvoidingView']>,
		default: false,
	},
	lockPageWhenOpened: {
		type: Boolean as PropType<ModalModel['lockPageWhenOpened']>,
		default: true,
	},
};
