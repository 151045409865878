import Vue, { PropType } from 'vue';
import { FieldsFilter, FormValues } from '@/node_modules/@osp/design-system/types/forms';
import { equals } from '@/node_modules/@osp/design-system/assets/js/utilities/compare';
import { useFormsStore } from '~/@api/store/formsApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'GenericFormWrap',
	props: {
		formCode: { type: String, required: true },
		submitLabel: { type: String, required: true },
		submitBlock: { type: Boolean, default: false },
		hideSubmitButton: { type: Boolean, default: false },
		defaultValues: {
			type: Object as PropType<FormValues>,
			default: () => {},
		},
		fieldsFilter: { type: Function as PropType<FieldsFilter>, default: () => true },
		resetTimeout: { type: Number, default: 0 },
		triggerWrapClass: { type: String, default: '' },
		triggerClass: { type: String, default: '' },
	},
	data() {
		return {
			formData: useFormsStore(this.$store).state.forms[this.formCode],
			config: null,
		};
	},
	async fetch() {
		if (!this.formData?.config) {
			this.formData = await useFormsStore(this.$store).api.loadForm(this.formCode);
		}

		this.config = this.formData.config;
	},
	computed: {
		isValidationDisabled() {
			return useServerSettingsStore(this.$store).state.settings.formValidationDisabled;
		},
		recaptchaKey() {
			return useServerSettingsStore(this.$store).state.settings.recaptchaKey;
		},
	},
	watch: {
		'$store.state.forms.forms'(newForms, oldForms) {
			const newFormData = newForms[this.formCode];
			const oldFormData = oldForms[this.formCode];

			if (!oldFormData || equals(newFormData, oldFormData)) return;

			this.formData = newFormData;
		},
	},
	methods: {
		handleClearBackendError(fieldId) {
			const backendValidationErrors = this.formData.validationResponse.errors || [];

			useFormsStore(this.$store).api.storeValidationResponse(this.formCode, {
				...this.formData.validationResponse,
				errors: backendValidationErrors.filter((error) => error.field !== fieldId),
			});
		},
		resetForm() {
			this.$refs.form.resetForm();
		},
	},
});
