export default function throttle(fn: Function, delay: number) {
	let timer = 0;

	return (...parameters: any[]) => {
		const now = Date.now();

		if (now - timer < delay) return;

		timer = now;

		return fn(...parameters);
	};
}
