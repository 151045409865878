import { render, staticRenderFns } from "./messagebox-wrapper.vue?vue&type=template&id=16c106c9&scoped=true&"
import script from "./messagebox-wrapper.ts?vue&type=script&lang=ts&"
export * from "./messagebox-wrapper.ts?vue&type=script&lang=ts&"
import style0 from "./messagebox-wrapper.vue?vue&type=style&index=0&id=16c106c9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c106c9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Messagebox: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/messagebox/messagebox.vue').default})
