import Vue from 'vue';
import { SelectFieldProps } from './SelectField.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'SelectField',
	inheritAttrs: false,
	model: {
		prop: 'model-value',
		event: 'model-input',
	},
	props: SelectFieldProps,
	data() {
		return {
			value: '',
			isFocused: false,
		};
	},
	computed: {
		isLabelFloated(): boolean {
			return !!this.value || this.isFocused;
		},
		selectedLabel(): string {
			return this.options.find((el) => el.value === this.value)?.label || '';
		},
	},
	watch: {
		modelValue() {
			this.initialize();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.value = this.modelValue;
		},
		handleChange() {
			this.$emit('model-input', this.value);
		},
		handleFocus() {
			this.isFocused = true;
		},
		handleBlur() {
			this.isFocused = false;
		},
	},
});
