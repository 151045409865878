import DyComponentBase from './dy-component-base';

/**
 * Base component for Custom API DynamicYield components,
 * handling to get the custom JSON variation data
 */
export default DyComponentBase.extend({
	name: 'DynamicYieldComponentBase',
	computed: {
		dyVariation() {
			if (!this.dyChoice) {
				return undefined;
			}

			// Currently we expect to get returned only 1 variation from DY
			const dyVariation = (this.dyChoice?.variations as any[])?.find(
				(variation) => variation?.payload?.type === 'CUSTOM_JSON',
			);

			return dyVariation;
		},
	},
});
