import Vue from 'vue';
import { scrollPage } from '@/node_modules/@osp/utils/src/scroll-to';
import throttle from '../../assets/js/utilities/throttle';
import { ScrollToTopButtonProps } from './ScrollToTopButton.props';

// Constant(s) -------------------------------------------------------------------------------------

const THROTTLE_MS = 250;
const TOP_POSITION_OFFSET = 400; // Roughly 40-50% the height of modern screens

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'ScrollToTopButton',
	props: ScrollToTopButtonProps,
	data() {
		return {
			previousScrollPosition: 0,
			buttonIsVisible: false,
			throttledScroll: null as unknown as EventListener,
		};
	},
	mounted() {
		this.throttledScroll = throttle(this.checkPosition, THROTTLE_MS);

		window.addEventListener('scroll', this.throttledScroll);
		window.addEventListener('scrollend', this.checkPosition);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.throttledScroll);
		window.removeEventListener('scrollend', this.checkPosition);
	},
	methods: {
		scrollToTop: scrollPage,
		checkPosition() {
			const scrollPosition = window.scrollY;
			this.buttonIsVisible =
				this.previousScrollPosition > scrollPosition && scrollPosition > TOP_POSITION_OFFSET;
			this.previousScrollPosition = scrollPosition;
		},
	},
});
