import { Mixin, Vue } from '@/app-utils/decorators';

@Mixin
export class TogglePasswordMixin extends Vue {
	private showPassword = false;
	private passwordInputType: 'text' | 'password' = 'password';

	public get inputType() {
		return this.passwordInputType;
	}

	public get isPasswordVisible(): boolean {
		return this.showPassword;
	}

	public toggleVisibility() {
		this.showPassword = !this.showPassword;
		this.passwordInputType = this.showPassword ? 'text' : 'password';
	}
}
