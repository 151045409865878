import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';

// Model -------------------------------------------------------------------------------------------

export interface ListModel {
	uid: string;
	maxAmount?: number;
	heading?: Link;
	links?: Link[];
	more: Link;
}

// Props -------------------------------------------------------------------------------------------

export const ListProps: Props<ListModel> = {
	uid: {
		type: String as PropType<ListModel['uid']>,
		required: true,
	},
	maxAmount: {
		type: Number as PropType<ListModel['maxAmount']>,
	},
	heading: {
		type: Object as PropType<ListModel['heading']>,
	},
	links: {
		type: Array as PropType<ListModel['links']>,
	},
	more: {
		type: Object as PropType<ListModel['more']>,
		required: true,
	},
};
