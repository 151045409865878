import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface HtmlFragmentModel {
	html?: string;
	wrappingTag?: string;
	whitelistTags?: string[];
}

// Props -------------------------------------------------------------------------------------------

export const HtmlFragmentProps: Props<HtmlFragmentModel> = {
	html: {
		type: String as PropType<HtmlFragmentModel['html']>,
	},
	wrappingTag: {
		type: String as PropType<HtmlFragmentModel['wrappingTag']>,
	},
	whitelistTags: {
		type: Array as PropType<HtmlFragmentModel['whitelistTags']>,
	},
};
