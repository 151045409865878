import Vue from 'vue';
import { HEXCOLOR, setContrastColor } from '../../assets/js/utilities/setContrastColor';
import { CheckboxProps } from './Checkbox.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Checkbox',
	model: {
		prop: 'modelValue',
		event: 'change',
	},
	props: CheckboxProps,
	data() {
		return {
			isMounted: false,
		};
	},
	computed: {
		isChecked(): boolean {
			if (!this.value) {
				return false;
			}

			if (typeof this.value === 'boolean' || !Array.isArray(this.modelValue)) {
				return this.modelValue === this.value;
			} else {
				return this.modelValue.includes(this.value);
			}
		},
		fallbackAriaLabel() {
			if (!this.isMounted || !this.hideLabel) return;

			return (this.$refs.labelWrap as HTMLElement).textContent?.trim();
		},
	},
	mounted() {
		this.isMounted = true;
	},
	methods: {
		updateInput(event: Event) {
			const isChecked = (event.target as HTMLInputElement).checked;

			if (!this.value) return;

			if (typeof this.value === 'boolean' || !Array.isArray(this.modelValue)) {
				return this.$emit('change', isChecked);
			}

			const newValue = [...this.modelValue];

			if (isChecked) {
				newValue.push(this.value);
			} else {
				newValue.splice(newValue.indexOf(this.value), 1);
			}
			this.$emit('change', newValue);
			this.$emit('single-change', typeof this.value === 'boolean' ? isChecked : this.value);
		},
		iconColor(color: string): string {
			if (color.startsWith('linear-gradient')) {
				return HEXCOLOR.BLACK;
			}

			return setContrastColor(color);
		},
		customStyles() {
			return this.color
				? {
						background: this.color,
						'border-color': this.color === '#ffffff' ? '#B3B3B3' : this.color,
						color: this.iconColor(this.color),
					}
				: {};
		},
	},
});
