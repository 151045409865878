var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"tw-relative tw-flex tw-items-center tw-font-secondary tw-border tw-rounded",class:{
		'tw-border-snow': !_vm.isFocused && !_vm.hasError,
		'tw-border-storm': _vm.isFocused && !_vm.hasError,
		'tw-border-shiraz': _vm.hasError,
		'tw-bg-white tw-text-black hover:tw-bg-dove': !_vm.disabled,
		'tw-bg-snow tw-text-storm tw-cursor-not-allowed': _vm.disabled,
	},attrs:{"data-name":"select-field","data-id":_vm.uid}},[(_vm.label)?_c('div',{staticClass:"tw-absolute tw-left-4 tw-transform tw-text-storm tw-font-normal tw-overflow-hidden tw-pointer-events-none tw-overflow-ellipsis tw-whitespace-nowrap tw-cursor-text tw-transition-all tw-duration-100",class:{
			'tw-text-3.5 tw-top-1/2 tw--translate-y-1/2': !_vm.isLabelFloated,
			'tw-text-3 tw-top-2 tw--translate-y-0': _vm.isLabelFloated,
		}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"tw-px-4 tw-text-base tw-font-bold tw-w-full tw-outline-none; tw-transition-none tw-outline-none",class:{
			'tw-py-4': !_vm.label || (_vm.label && !_vm.isLabelFloated),
			'tw-pt-6 tw-pb-2': _vm.label && _vm.isLabelFloated,
		}},[_c('div',{staticClass:"tw-h-6 tw-mt-px tw-mb-px"},[_vm._v(_vm._s(_vm.selectedLabel))])]),_c('div',{staticClass:"tw-flex-shrink-0 tw-mr-4",class:{ 'tw-text-storm': _vm.isLabelFloated, 'tw-text-dust': !_vm.isLabelFloated }},[_c('Ico',{staticClass:"tw-ml-4",attrs:{"name":"chevron-right-small","size":"24px"}})],1),_c('select',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"tw-w-full tw-absolute tw-inset-0 tw-opacity-0",class:{ 'tw-cursor-not-allowed': _vm.disabled },attrs:{"id":_vm.id,"disabled":_vm.disabled,"name":_vm.name},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.value=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.handleChange],"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'select',_vm.$attrs,false),_vm.$listeners),_vm._l((_vm.options),function(option){return _c('option',{key:option.id,domProps:{"value":option.value,"selected":_vm.value === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }