import { PropType } from 'vue';
import { Props } from '@/node_modules/@osp/design-system/types/core';

// Model -------------------------------------------------------------------------------------------

export interface DYPromoBarModel {
	uid: string;
	selectorName: string;
}

// Props -------------------------------------------------------------------------------------------

export const DYPromoBarProps: Props<DYPromoBarModel> = {
	uid: {
		type: String as PropType<DYPromoBarModel['uid']>,
		required: true,
	},
	selectorName: {
		type: String as PropType<DYPromoBarModel['selectorName']>,
		required: true,
	},
};
