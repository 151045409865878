import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface KeyboardAvoidingViewModel {
	uid: string;
	active?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const KeyboardAvoidingViewProps: Props<KeyboardAvoidingViewModel> = {
	uid: {
		type: String as PropType<KeyboardAvoidingViewModel['uid']>,
		required: true,
	},
	active: {
		type: Boolean as PropType<KeyboardAvoidingViewModel['active']>,
		default: true,
	},
};
