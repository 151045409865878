import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface ScrollToTopButtonModel {
	uid: string;
}

// Props -------------------------------------------------------------------------------------------

export const ScrollToTopButtonProps: Props<ScrollToTopButtonModel> = {
	uid: {
		type: String as PropType<ScrollToTopButtonModel['uid']>,
		required: true,
	},
};
