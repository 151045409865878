
















import Vue from 'vue';

export default Vue.extend({
	props: {
		animated: { type: Boolean, default: false },
		icon: { type: String, required: true },
		text: { type: String, required: true },
	},
});
