import Vue, { CreateElement, VNode } from 'vue';
import { Fragment } from '../FragmentComponent/FragmentComponent';

// Component ---------------------------------------------------------------------------------------
// This wrapper can be used for edge case appendChild errors appearing in combination with v-if and
// lazy hydration. Suggestion is from: https://github.com/maoberlehner/vue-lazy-hydration/issues/83#issuecomment-967107044

export default Vue.extend({
	name: 'HydrateableClientOnly',
	data() {
		return {
			render: false,
		};
	},
	mounted() {
		this.render = true;
	},
	render(h: CreateElement): VNode {
		return this.render ? h(Fragment, this.$slots.default) : h('span');
	},
});
