export enum SectionTitleType {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
}

export interface Tag {
	code: string;
	color?: string;
	icon?: string;
	label: string;
	onClick?: void;
}
