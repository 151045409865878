import { setSafeTimeout } from './timeout';

// Default debounce - waits for the last occurance to be executed
export const debounce = (fn: Function, delay: number) => {
	let timer: number | null;

	return (...parameters: any[]) => {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setSafeTimeout(() => fn(...parameters), delay);
	};
};

// Leading debounce - executes the first occurance and then ignoring further incoming events during the wait time
export const debounceLeading = (fn: Function, duration: number) => {
	let timer: number | null;

	return (...parameters: any[]) => {
		if (!timer) {
			// @ts-ignore
			fn.apply(this, parameters);
		}

		if (timer) {
			clearTimeout(timer);
		}

		timer = setSafeTimeout(() => {
			timer = null;
		}, duration);
	};
};

export default debounce;
