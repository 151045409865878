// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".osp_master--2vKQR{display:flex;width:100%;flex-direction:column}.osp_master--2vKQR>footer,.osp_master--2vKQR>header,.osp_master--2vKQR>main{display:flex;width:100%;flex-shrink:0;flex-direction:column}.osp_master--2vKQR>main{position:relative;flex-grow:1}.border_bottom_header--3FDSE>header,.border_bottom_main--piZ4o>main{border-bottom:1px solid #ccc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"osp_master": "osp_master--2vKQR",
	"border_bottom_header": "border_bottom_header--3FDSE",
	"border_bottom_main": "border_bottom_main--piZ4o"
};
module.exports = ___CSS_LOADER_EXPORT___;
