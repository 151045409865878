import Vue from 'vue';
import isTouchDevice from '@/node_modules/@osp/design-system/assets/js/utilities/isTouchDevice';
import { FORCE_DEVICE } from '~/@constants/global';
import { Breakpoint, UserAgentDeviceCategory } from '~/@api/store.types';
import { useServerContextStore } from '~/@api/store/serverContextApi';

export default Vue.extend({
	name: 'TouchRedirectModal',
	data() {
		return {
			isModalVisible: false,
			deviceCategoryToForce: undefined as undefined | UserAgentDeviceCategory,
		};
	},
	watch: {
		'$store.state.servercontext.userAgent.resolution': {
			immediate: true,
			handler(newValue?: Breakpoint) {
				if (!newValue) return;

				this.checkForSwitchTo(newValue);
			},
		},
	},
	methods: {
		checkForSwitchTo(resolutionBreakpoint: Breakpoint) {
			if (!isTouchDevice()) return;

			const resolutionCategory = resolutionBreakpoint.toString().toLowerCase().split('_')[0];
			const deviceCategory = useServerContextStore(this.$store).state.userAgent.deviceCategory;

			this.deviceCategoryToForce =
				deviceCategory === 'desktop' && deviceCategory !== resolutionCategory
					? resolutionCategory
					: undefined;

			if (this.deviceCategoryToForce) {
				this.showModalAfterCmp();
			}
		},
		showModalAfterCmp() {
			// Ensure switch modal will be shown after cmp modal
			const cmpObject = (window as any).__cmp;

			if (cmpObject('consentStatus')?.userChoiceExists) {
				this.showModal();
				return;
			}

			if (!cmpObject || cmpObject)
				cmpObject('addEventListener', [
					'consent',
					() => {
						this.showModal();
					},
					false,
				]);
		},
		showModal() {
			this.isModalVisible = true;
		},
		switchToTablet() {
			const url = new URL(window.location.href);

			url.searchParams.append(FORCE_DEVICE, this.deviceCategoryToForce);

			window.location.href = url.toString();
		},
	},
});
