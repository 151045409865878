












import Vue, { PropType } from 'vue';
import { Link } from '../../../types/link';

// Constant(s) -------------------------------------------------------------------------------------

const DEFAULT_ACTIVE_FILTER = '';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	props: {
		filters: { type: Array as PropType<string[]>, required: true },
		link: { type: Object as PropType<Link>, required: true },
	},
	data() {
		return {
			activeFilter: DEFAULT_ACTIVE_FILTER,
		};
	},
	methods: {
		toggleClass(filter: string) {
			this.activeFilter = filter === this.activeFilter ? DEFAULT_ACTIVE_FILTER : filter;

			this.$emit('click', filter);
		},
	},
});
