import Vue from 'vue';
import { setSafeInterval } from '../../assets/js/utilities/timeout';
import { UspBarProps } from './UspBar.props';
import UspBarItem from './patrials/UspBar__Item.vue';
// Constant(s) -------------------------------------------------------------------------------------

const COOKIE_NAME = 'ospUSPBarClosed';
const ANIMATION_DELAY = 3100;

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'UspBar',
	components: { UspBarItem },
	props: UspBarProps,
	data() {
		return {
			renderingInProgress: false,
			hidden: false,
			activeIndex: 0,
			intervalId: 0 as number | null,
		};
	},
	watch: {
		isAnimating(newValue) {
			if (!this.animated) return;

			if (newValue && !this.intervalId) {
				this.triggerAnimation();
			} else if (!newValue && this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = 0;
			}
		},
	},
	created() {
		this.hidden = this.$cookies.get(COOKIE_NAME);
		this.renderingInProgress = process.client && !this.animated;
	},
	mounted() {
		if (this.animated && this.isAnimating) {
			this.triggerAnimation();
		}
	},
	beforeDestroy() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	},
	methods: {
		close(): void {
			let expires = null;

			if (this.cookieExpires) {
				expires = new Date();

				expires.setDate(expires.getDate() + this.cookieExpires);
			}

			const options = { ...(expires && { expires }), path: '/' };

			this.$cookies.set(COOKIE_NAME, true, options);
			this.$destroy();
			this.$el?.parentElement?.removeChild(this.$el);
		},
		isItemActive(index: number): boolean {
			return this.animated ? this.activeIndex === index : true;
		},
		increaseActiveIndex(): void {
			this.activeIndex = (this.activeIndex + 1) % this.items.length;
		},
		triggerAnimation(): void {
			this.intervalId = setSafeInterval(this.increaseActiveIndex, ANIMATION_DELAY);
		},
		onUspBarItemMounted(itemIndex: number): void {
			if (itemIndex === this.items.length - 1) {
				this.$nextTick(() => {
					this.renderingInProgress = false;
				});
			}
		},
	},
});
