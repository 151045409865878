var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"radio tw-relative tw-inline-flex tw-mb-1 tw-items-center tw-align-middle",class:{
		'radio--checked': _vm.isChecked,
		'radio--disabled tw-text-dust tw-cursor-not-allowed': _vm.disabled,
		'tw-cursor-pointer': !_vm.disabled,
		'tw-text-shiraz': _vm.hasError,
		'tw-text-3.5 tw-font-bold': !_vm.clearLabelStyles,
	},attrs:{"data-name":"radio","data-id":_vm.uid}},[_c('input',{staticClass:"tw-sr-only tw-opacity-0",attrs:{"id":_vm.id,"type":"radio","disabled":_vm.disabled,"name":_vm.name},domProps:{"checked":_vm.isChecked},on:{"change":_vm.onChange}}),_c('div',{staticClass:"radio__icon tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-border tw-border-solid tw-rounded-full",class:{
			'tw-mr-4': _vm.label || _vm.$slots.default,
			'tw-border-shiraz tw-bg-white': _vm.hasError,
			'tw-border-black tw-bg-white': !_vm.hasError && !_vm.disabled,
			'tw-border-dust tw-bg-snow': _vm.disabled,
		}},[(_vm.isChecked)?_c('div',{staticClass:"tw-w-4 tw-h-4 tw-rounded-full",class:{
				'tw-bg-shiraz': _vm.hasError,
				'tw-bg-black': !_vm.hasError && !_vm.disabled && _vm.isChecked,
				'tw-bg-dust': _vm.disabled,
			}}):_vm._e()]),_c('div',{staticClass:"tw-w-full",class:{ 'tw-mt-0.5': !_vm.clearLabelStyles }},[_vm._t("default",function(){return [(_vm.label)?_c('HtmlFragment',{attrs:{"wrapping-tag":"div","html":_vm.label}}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }