import { ValidationRule } from 'vuelidate/lib/validators';

export enum FormFieldType {
	CAPTCHA = 'CAPTCHA',
	CHECKBOX = 'CHECKBOX',
	EMAIL = 'EMAIL',
	LABEL = 'LABEL',
	PASSWORD = 'PASSWORD',
	RADIO_BUTTON = 'RADIO_BUTTON',
	RADIO_GROUP = 'RADIO_GROUP',
	SELECT = 'SELECT',
	SUBMIT = 'SUBMIT',
	TEXTFIELD = 'TEXTFIELD',
}

export enum FormFieldInputMode {
	DATE = 'DATE',
	DECIMAL = 'DECIMAL',
	EMAIL = 'EMAIL',
	NONE = 'NONE',
	NUMERIC = 'NUMERIC',
	SEARCH = 'SEARCH',
	TEL = 'TEL',
	TEXT = 'TEXT',
	TEXTAREA = 'TEXTAREA',
	URL = 'URL',
}

export interface FormValues {
	[fieldId: string]: number | string | boolean | undefined;
}

export interface FormFieldValidation {
	errorMessage?: string;
}

export interface FormFieldMaxLengthValidation extends FormFieldValidation {
	maxLength?: number;
}

export interface FormFieldMinLengthValidation extends FormFieldValidation {
	minLength?: number;
}

export interface FormFieldRegexValidation extends FormFieldValidation {
	regexPattern?: string;
}

export interface FormFieldRequiredValidation extends FormFieldValidation {
	required?: boolean;
}

export interface FormFieldValidations {
	maxLength?: FormFieldMaxLengthValidation;
	minLength?: FormFieldMinLengthValidation;
	regex?: FormFieldRegexValidation;
	required?: FormFieldRequiredValidation;
}

export interface FieldValidations {
	[key: string]: ValidationRule | (() => ValidationRule);
}

export interface FormField {
	autocomplete?: boolean;
	buttons?: FormField[];
	checked?: boolean;
	id: string;
	inputMode?: FormFieldInputMode;
	label?: string;
	placeholder?: string;
	styleClass?: string;
	type?: FormFieldType;
	validations?: FormFieldValidations;
	value?: string;
}

export interface FormError {
	errorMessage?: string;
	field?: string;
}

export interface FormConfiguration {
	autocomplete?: boolean;
	code: string;
	fields?: FormField[];
}

export interface ValidationErrorResponse {
	errors?: FormError[];
	response?: unknown;
}

export interface ValidationsValues {
	[key: string]: any;
}

export interface FormModel {
	[fieldId: string]: string;
}

export interface TypeAheadData {
	label: string;
	model: FormModel;
}

export interface InputHandler {
	typeAhead: (model: FormModel) => Promise<TypeAheadData[]>;
}
export interface FormData {
	config: FormConfiguration;
	validationResponse: ValidationErrorResponse;
	inputHandler: {
		[fieldId: string]: InputHandler;
	};
}

export interface InputPrice {
	label: string;
	id: string;
	value: number;
	minValue: number;
	maxValue: number;
}

export interface Range {
	min: number;
	max: number;
}

export type FieldsFilter = (field: FormField, values: FormValues) => boolean;
