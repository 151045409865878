import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface RadioModel {
	uid: string;
	id: string;
	name: string;
	modelValue?: string;
	value: string;
	hasError?: boolean;
	label?: string;
	disabled?: boolean;
	clearLabelStyles?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const RadioProps: Props<RadioModel> = {
	uid: {
		type: String as PropType<RadioModel['uid']>,
		required: true,
	},
	id: {
		type: String as PropType<RadioModel['id']>,
		required: true,
	},
	name: {
		type: String as PropType<RadioModel['name']>,
		required: true,
	},
	modelValue: {
		type: [String] as PropType<RadioModel['modelValue']>,
		default: undefined,
	},
	value: {
		type: String as PropType<RadioModel['value']>,
		required: true,
	},
	hasError: {
		type: Boolean as PropType<RadioModel['hasError']>,
		default: false,
	},
	label: {
		type: String as PropType<RadioModel['label']>,
		default: '',
	},
	disabled: {
		type: Boolean as PropType<RadioModel['disabled']>,
		default: false,
	},
	clearLabelStyles: {
		type: Boolean as PropType<RadioModel['clearLabelStyles']>,
		default: false,
	},
};
