import Vue from 'vue';
import { RadioProps } from './Radio.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Radio',
	inheritAttrs: false,
	model: {
		prop: 'modelValue',
		event: 'change',
	},
	props: RadioProps,
	computed: {
		isChecked(): boolean {
			return this.modelValue === this.value && this.modelValue !== '';
		},
	},
	methods: {
		onChange() {
			this.$emit('change', this.value);
		},
	},
});
