import Vue, { CreateElement, VNode } from 'vue';
import { addInnerHtml } from '../../assets/js/utilities/addInnerHtml';
import { HtmlFragmentProps } from './HtmlFragment.props';

export default Vue.extend({
	name: 'HtmlFragment',
	props: HtmlFragmentProps,
	data() {
		return {
			defaultWhitelistTags: ['div', 'span', 'p', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
		};
	},
	methods: {
		getWrappingTag(): string | undefined {
			if (
				!this.wrappingTag ||
				(!this.defaultWhitelistTags.includes(this.wrappingTag) &&
					!this.whitelistTags?.includes(this.wrappingTag))
			) {
				return 'span';
			}

			return this.wrappingTag;
		},
	},
	render(h: CreateElement): VNode {
		return h(this.getWrappingTag(), this.html ? addInnerHtml(this.html.trim()) : {});
	},
});
