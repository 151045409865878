import { PropType } from 'vue';
import { Props } from '../../types/core';
import { FormConfiguration, FieldsFilter, FormData, FormValues } from '../../types/forms';

// Model -------------------------------------------------------------------------------------------

export interface GenericFormModel {
	uid: string;
	config: FormConfiguration;
	fieldsFilter?: FieldsFilter;
	defaultValues?: FormValues;
	submitLabel: string;
	submitBlock?: boolean;
	hideSubmitButton?: boolean;
	isValidationDisabled?: boolean;
	formData?: FormData;
	recaptchaKey?: string;
	resetTimeout?: number;
	triggerWrapClass?: string;
	triggerClass?: string;
}

// Props -------------------------------------------------------------------------------------------

export const GenericFormProps: Props<GenericFormModel> = {
	uid: {
		type: String as PropType<GenericFormModel['uid']>,
		required: true,
	},
	config: {
		type: Object as PropType<GenericFormModel['config']>,
		required: true,
	},
	fieldsFilter: {
		type: Function as PropType<GenericFormModel['fieldsFilter']>,
		default: () => true,
	},
	defaultValues: {
		type: Object as PropType<GenericFormModel['defaultValues']>,
		default: null,
	},
	submitLabel: {
		type: String as PropType<GenericFormModel['submitLabel']>,
		required: true,
	},
	submitBlock: {
		type: Boolean as PropType<GenericFormModel['submitBlock']>,
		default: false,
	},
	hideSubmitButton: {
		type: Boolean as PropType<GenericFormModel['hideSubmitButton']>,
		default: false,
	},
	isValidationDisabled: {
		type: Boolean as PropType<GenericFormModel['isValidationDisabled']>,
		default: false,
	},
	formData: {
		type: Object as PropType<GenericFormModel['formData']>,
		default: null,
	},
	recaptchaKey: {
		type: String as PropType<GenericFormModel['recaptchaKey']>,
		default: '',
	},
	resetTimeout: {
		type: Number as PropType<GenericFormModel['resetTimeout']>,
		default: 0,
	},
	triggerWrapClass: {
		type: String as PropType<GenericFormModel['triggerWrapClass']>,
		default: '',
	},
	triggerClass: {
		type: String as PropType<GenericFormModel['triggerClass']>,
		default: '',
	},
};
