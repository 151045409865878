export enum HEXCOLOR {
	BLACK = '#000000',
	WHITE = '#ffffff',
}

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
export function setContrastColor(hexColor: string): string {
	const extractedColor: string = hexColor.split('#')[1];
	const r = parseInt(extractedColor.slice(0, 2), 16);
	const g = parseInt(extractedColor.slice(2, 4), 16);
	const b = parseInt(extractedColor.slice(4, 6), 16);
	const yiq = r * 0.299 + g * 0.587 + b * 0.114;

	return yiq >= 150 ? HEXCOLOR.BLACK : HEXCOLOR.WHITE;
}
