






import Vue, { PropType } from 'vue';
import { ListModel } from '../../List/List.props';
import { SectionTitleModel } from '../../SectionTitle/SectionTitle.props';

export default Vue.extend({
	props: {
		title: { type: Object as PropType<SectionTitleModel>, required: true },
		list: { type: Object as PropType<ListModel>, required: true },
	},
});
