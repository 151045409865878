import Vue from 'vue';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { KeyboardAvoidingViewProps } from './KeyboardAvoidingView.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'KeyboardAvoidingView',
	props: KeyboardAvoidingViewProps,
	data() {
		return {
			isActive: this.active ?? true,
			visualViewportHeight: undefined as number | undefined,
			resizeChecker: null as number | null,
		};
	},
	watch: {
		isActive(newValue) {
			this.checkForResize(newValue);
		},
	},
	beforeDestroy() {
		this.checkForResize(false);
	},
	mounted() {
		if (this.isActive) {
			this.checkForResize(true);
		}
	},
	methods: {
		checkForResize(listen: boolean): void {
			if (process.server) return;

			if (!listen && this.resizeChecker) {
				clearTimeout(this.resizeChecker);
				this.updateVisualViewportHeight(true);
				return;
			}

			if (!this.visualViewportHeight) {
				this.updateVisualViewportHeight();
			}

			this.resizeChecker = setSafeTimeout(() => {
				this.checkUpdateViewportHeight();
				this.checkForResize(this.isActive);
			}, 250);
		},
		checkUpdateViewportHeight() {
			if (process.server || (window.visualViewport?.height ?? 0) === this.visualViewportHeight) {
				return;
			}

			this.updateVisualViewportHeight();
		},
		updateVisualViewportHeight(reset?: boolean) {
			this.visualViewportHeight =
				process.client && !reset ? window.visualViewport?.height : undefined;

			this.$emit('updated', this.visualViewportHeight);
		},
	},
});
