// Scroll functions to use globally

// scrollIntoView version with element as argument and 'window.scrollBy' method used
import { setSafeTimeout } from '../../../assets/js/utilities/timeout';

type ScrollIntoViewElementIdentifier = Element | string | null;

export function scrollIntoView(
	targetElement: ScrollIntoViewElementIdentifier,
	behavior: ScrollBehavior = 'smooth',
	verticalAlign: 'top' | 'middle' = 'top',
	containerElement?: ScrollIntoViewElementIdentifier,
) {
	if (typeof targetElement === 'string') {
		targetElement = process.client ? document.querySelector(targetElement) : null;
	}

	if (typeof containerElement === 'string') {
		containerElement = process.client ? document.querySelector(containerElement) : null;
	}

	if (!targetElement || !window) {
		return;
	}

	const { top, left } = getTopLeft(targetElement, containerElement || undefined, verticalAlign);

	// use window.scrollBy as Safari does not support window.scrollIntoView smooth scroll behaviour

	// Avoid to trigger user interaction scroll listener by code triggered scrolling
	const userInteractionModule = (window as any).osp?.userInteraction;
	(userInteractionModule || {}).autoscrollIsHappening = true;

	(containerElement || window).scrollBy({
		top,
		left,
		behavior,
	});

	if (userInteractionModule) {
		setSafeTimeout(() => {
			// Re-Allow scroll listener to detect real user interaction
			userInteractionModule.autoscrollIsHappening = false;
			userInteractionModule.autoscrollEndPosition = window.scrollY;
		}, 1500);
	}
}

function getTopLeft(
	targetElement: Element,
	containerElement?: Element,
	verticalAlign?: string,
): {
	top: number;
	left: number;
} {
	const boundingRect = (targetElement as HTMLElement).getBoundingClientRect();
	let top = boundingRect.y;
	let left = boundingRect.x;

	if (verticalAlign === 'middle') {
		const containerHeight = containerElement ? containerElement.clientHeight : window.innerHeight;
		const containerWidth = containerElement ? containerElement.clientWidth : window.innerWidth;

		top = boundingRect.y - containerHeight / 2 + boundingRect.height / 2;
		left = boundingRect.x - containerWidth / 2 + boundingRect.width / 2;
	}

	return { top, left };
}
