import LazyHydrate from 'vue-lazy-hydration';
import { Bool, Component, Prop, Vue, Watch } from '@/app-utils/decorators';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useUxStore } from '~/@api/store/uxApi';

export const LayoutTypeName = 'LayoutType';

@Component({
	name: LayoutTypeName,
})
export class LayoutType extends Vue {
	lazyHydrate = (h, component) => h(LazyHydrate, { props: { whenIdle: true } }, [component]);
	private layoutChangeInProgress = false;

	@Bool()
	public center: boolean;

	@Bool()
	public centerSmall: boolean;

	@Bool()
	public centerBig: boolean;

	@Bool()
	public fullWidth: boolean;

	@Bool()
	public overflowHidden: boolean;

	@Bool()
	public smallMargin: boolean;

	@Bool()
	public noPadding: boolean;

	@Bool()
	public noPaddingBottom: boolean;

	@Bool()
	public noPaddingTopBottom: boolean;

	@Bool()
	public spacer: boolean;

	@Bool()
	public supportLegacyGrid: boolean;

	@Prop()
	public tag: string;

	@Watch('centerSmall')
	centerSmallWatcher() {
		this.layoutChangeInProgress = true;
	}

	@Watch('centerBig')
	centerBigHandler() {
		this.layoutChangeInProgress = true;
	}

	get layoutStyle() {
		return {
			[this.$style.layout_center]: this.center,
			[this.$style.layout_center_small]: this.centerSmall,
			[this.$style.layout_center_big]: this.centerBig,
			[this.$style.no_padding]: this.noPadding,
			[this.$style.no_padding_bottom]: this.noPaddingBottom,
			[this.$style.no_padding_top_bottom]: this.noPaddingTopBottom,
			[this.$style.layout_full_width]: this.fullWidth,
			[this.$style.overflow_hidden]: this.overflowHidden,
			[this.$style.spacer]: this.spacer,
			[this.$style.small_margin]: this.smallMargin,
			[this.$style.support_legacy_grid]: this.supportLegacyGrid,
		};
	}

	get deviceStyle() {
		const { state: mediaqueryState } = useMediaqueryStore(this.$store);

		return [
			this.$style[mediaqueryState.device.category],
			this.$style[mediaqueryState.device.orientation],
		];
	}

	get pageChangeInProgress() {
		return useUxStore(this.$store).state.pageSwitchInProgress;
	}

	@Watch('pageChangeInProgress')
	pageChangeInProgressWatcher(newValue) {
		if (!newValue) {
			this.layoutChangeInProgress = false;
		}
	}

	get style() {
		return [
			this.layoutStyle,
			...this.deviceStyle,
			{
				'tw-opacity-0': this.layoutChangeInProgress && this.pageChangeInProgress,
			},
		];
	}

	render(h) {
		return h(this.tag || 'div', { class: this.style }, this.$slots.default);
	}
}

export { LayoutType as default };
