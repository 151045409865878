import Vue, { PropType } from 'vue';
import { RegistrationEmailMixin } from '@/components/mixins/registration-email-mixin';
import { TogglePasswordMixin } from '@/components/mixins/toggle-password';
import { RegistrationInformation } from '@/generated/hybris-raml-api';

export const RegistrationFormName = 'RegistrationForm';

export default Vue.extend({
	name: 'RegistrationForm',
	mixins: [TogglePasswordMixin, RegistrationEmailMixin],
	props: {
		registrationData: { type: Object as PropType<RegistrationInformation>, required: true },
		isCheckout: { type: Boolean, default: false },
	},
	data() {
		return {
			registerForNewstletter: false,
		};
	},
	computed: {
		trusties() {
			return this.registrationData.trusties || [];
		},
	},
	methods: {
		submit(data) {
			if (!data.showCaptcha || data.captcha !== null) {
				this.$emit('submit', {
					...data,
					...(data.title ? { title: { code: data.title } } : {}),
					...(this.registerForNewstletter ? { registerForNewstletter: true } : {}),
				});
			}
		},
		goToGuestMode() {
			this.$emit('toggle-guest', { value: true, email: this.getEmailInputValue() });
		},
	},
});
