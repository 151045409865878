const sortBy = (key: string, ignoreCase: boolean) => {
	return (a: any, b: any): number => {
		const valueA = ignoreCase && a[key].toLowerCase ? a[key].toLowerCase() : a[key];
		const valueB = ignoreCase && b[key].toLowerCase ? b[key].toLowerCase() : b[key];

		if (valueA > valueB) {
			return 1;
		}

		return valueA < valueB ? -1 : 0;
	};
};

export default (input: any[], by: string, ignoreCase = false): any[] => {
	return input.concat().sort(sortBy(by, ignoreCase));
};
