import { PropType } from 'vue';
import { Props } from '../../types/core';
import { SectionTitleType } from '../../types/tag';
import { CLSModel, CLSProps } from '../../types/clsModel';

// Model -------------------------------------------------------------------------------------------

export interface SectionTitleModel extends CLSModel {
	title: string;
	type?: SectionTitleType;
}

// Props -------------------------------------------------------------------------------------------

export const SectionTitleProps: Props<SectionTitleModel> = {
	...CLSProps,
	title: {
		type: String as PropType<SectionTitleModel['title']>,
		required: true,
	},
	type: {
		type: String as PropType<SectionTitleModel['type']>,
		default: SectionTitleType.PRIMARY,
	},
};
