import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export function lock(unlockedElement?: Element) {
	if (!unlockedElement) return;

	disableBodyScroll(unlockedElement);
}

export function unlock() {
	clearAllBodyScrollLocks();
}

export default {
	lock,
	unlock,
};
