





import Vue from 'vue';

export default Vue.extend({
	props: {
		id: { type: String, required: true },
		label: { type: String, required: true },
	},
	computed: {
		activeTab(): string {
			return this.$parent.$data.activeTab;
		},
	},
});
