










import Vue from 'vue';

export default Vue.extend({
	props: {
		filter: { type: String, required: true },
		active: { type: String, required: true },
	},
	computed: {
		filterClasses() {
			if (this.filter === this.active) {
				return 'tw-text-white tw-bg-black';
			}

			return 'tw-text-black';
		},
	},
});
