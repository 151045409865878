import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface OverlayModel {
	// uid: string;
}

// Props -------------------------------------------------------------------------------------------

export const OverlayProps: Props<OverlayModel> = {
	// uid: {
	// 	type: String as PropType<OverlayModel['uid']>,
	// 	required: true,
	// },
};
