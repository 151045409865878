import { ListModel } from '../components/List/List.props';
import { MediaModel } from '../components/Media/Media.props';
import { Link } from './link';

export interface Card {
	image: MediaModel;
	list: ListModel;
	category?: Link;
}

export enum FlyoutType {
	BRANDS = 'FlyoutBrands',
	IMAGE = 'FlyoutImages',
	IMAGETITLE = 'FlyoutImagetitle',
	LIST = 'FlyoutList',
	LISTTITLE = 'FlyoutListTitle',
	MASONRY = 'FlyoutMasonry',
}
