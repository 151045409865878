import Vue from 'vue';
import { Link } from '../../types/link';
import { SectionTitleType } from '../../types/tag';
import { ListModel } from '../List/List.props';
import { SectionTitleModel } from '../SectionTitle/SectionTitle.props';
import { FlyoutBrandsProps } from './FlyoutBrands.props';

// Constant(s) -------------------------------------------------------------------------------------

export const FILTER_SPECIAL = '0-9';
export const DEFAULT_FILTERS = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
	FILTER_SPECIAL,
];

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'FlyoutBrands',
	props: FlyoutBrandsProps,
	data() {
		return {
			filters: DEFAULT_FILTERS,
			filtered: false,
			links: [] as Link[],
			selectedFilter: '',
		};
	},
	computed: {
		allBrandsLink(): Link {
			return {
				href: this.brandsUrl,
				text: this.$t(this.$i18nKeys.flyoutBrands.allBrands) as string,
			};
		},
		topBrandsTitle(): SectionTitleModel {
			return {
				title: this.$t(this.$i18nKeys.flyoutBrands.top) as string,
				type: SectionTitleType.SECONDARY,
			};
		},
		highlightedBrandsTitle(): SectionTitleModel {
			return {
				title: this.$t(this.$i18nKeys.flyoutBrands.highlighted) as string,
				type: SectionTitleType.SECONDARY,
			};
		},
		filteredBrandsTitle(): SectionTitleModel {
			return {
				title: this.$t(this.$i18nKeys.flyoutBrands.filtered) as string,
				type: SectionTitleType.SECONDARY,
			};
		},
		filteredBrands(): ListModel {
			return {
				uid: `${this.uid}_FILTERED_BRANDS`,
				links: this.links,
				more: { text: '', href: '' },
			};
		},
	},
	methods: {
		handleButtonClick(filter: string) {
			if (filter === this.selectedFilter) {
				this.filtered = !this.filtered;

				return;
			}

			let links = this.brands.filter((brand) => filter === brand.name.charAt(0).toUpperCase());

			if (!links.length && filter === FILTER_SPECIAL) {
				links = this.brands.filter(
					(brand) => !DEFAULT_FILTERS.includes(brand.name.charAt(0).toUpperCase()),
				);
			}

			if (!links.length) {
				const alphabet = DEFAULT_FILTERS.slice(0, -1);

				links = this.brands.filter((brand) => !alphabet.includes(brand.name.charAt(0)));
			}

			this.links = links
				.map((brand): Link => ({ text: brand.name, href: brand.url }))
				.sort((prev, next) => prev.text.localeCompare(next.text));
			this.selectedFilter = filter;
			this.filtered = true;
		},
	},
});
