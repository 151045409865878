import { PropType } from 'vue';
import { Props } from '../../types/core';
import { FormField } from '../../types/forms';

// Model -------------------------------------------------------------------------------------------

export interface SelectFieldModel {
	uid: string;
	id: string;
	name: string;
	modelValue: string;
	options: FormField[];
	hasError?: boolean;
	label?: string;
	disabled?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const SelectFieldProps: Props<SelectFieldModel> = {
	uid: {
		type: String as PropType<SelectFieldModel['uid']>,
		required: true,
	},
	id: {
		type: String as PropType<SelectFieldModel['id']>,
		required: true,
	},
	name: {
		type: String as PropType<SelectFieldModel['name']>,
		required: true,
	},
	modelValue: {
		type: String as PropType<SelectFieldModel['modelValue']>,
		default: undefined,
	},
	options: {
		type: Array as PropType<SelectFieldModel['options']>,
		required: true,
	},
	hasError: {
		type: Boolean as PropType<SelectFieldModel['hasError']>,
		default: false,
	},
	label: {
		type: String as PropType<SelectFieldModel['label']>,
		default: '',
	},
	disabled: {
		type: Boolean as PropType<SelectFieldModel['disabled']>,
		default: false,
	},
};
