import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface CheckboxModel {
	uid: string;
	modelValue: boolean | string[];
	value?: true | string;
	label?: string;
	hideLabel?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	clearLabelStyles?: boolean;
	color?: string;
}

// Props -------------------------------------------------------------------------------------------

export const CheckboxProps: Props<CheckboxModel> = {
	uid: {
		type: String as PropType<CheckboxModel['uid']>,
		required: true,
	},
	value: {
		type: [Boolean, String] as PropType<CheckboxModel['value']>,
		default: true,
	},
	modelValue: {
		type: [Array, Boolean] as PropType<CheckboxModel['modelValue']>,
		default: null,
	},
	label: {
		type: String as PropType<CheckboxModel['label']>,
		default: null,
	},
	hideLabel: {
		type: Boolean as PropType<CheckboxModel['hideLabel']>,
		default: false,
	},
	disabled: {
		type: Boolean as PropType<CheckboxModel['disabled']>,
		default: false,
	},
	hasError: {
		type: Boolean as PropType<CheckboxModel['hasError']>,
		default: false,
	},
	clearLabelStyles: {
		type: Boolean as PropType<CheckboxModel['clearLabelStyles']>,
		default: false,
	},
	color: {
		type: String as PropType<CheckboxModel['color']>,
		default: null,
	},
};
