






















import Vue, { PropType } from 'vue';
import { EnhancedBrand } from '../../../types/product';
import { ListModel } from '../../List/List.props';
import { SectionTitleModel } from '../../SectionTitle/SectionTitle.props';

export default Vue.extend({
	props: {
		topBrandsTitle: { type: Object as PropType<SectionTitleModel>, required: true },
		topBrands: { type: Array as PropType<EnhancedBrand[]>, required: true },
		highlightedBrandsTitle: { type: Object as PropType<SectionTitleModel>, required: true },
		highlightedBrands: { type: Object as PropType<ListModel>, required: true },
	},
});
