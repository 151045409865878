import { render, staticRenderFns } from "./FlyoutBrands.vue?vue&type=template&id=3caca8cc&"
import script from "./FlyoutBrands.ts?vue&type=script&lang=ts&"
export * from "./FlyoutBrands.ts?vue&type=script&lang=ts&"
import style0 from "./FlyoutBrands.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlyoutBrandsFilters: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Filters.vue').default,FlyoutBrandsFeatured: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__Featured.vue').default,FlyoutBrandsList: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/FlyoutBrands/partials/FlyoutBrands__List.vue').default})
