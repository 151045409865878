import Vue from 'vue';
import Logger from '@/node_modules/@osp/utils/src/logger';
import { TogglePasswordMixin } from '@/components/mixins/toggle-password';
import { useFormsStore } from '~/@api/store/formsApi';
import { useUserStore } from '~/@api/store/userApi';

export default Vue.extend({
	name: 'LoginForm',
	mixins: [TogglePasswordMixin],
	data() {
		return {
			componentName: 'LoginForm',
			isForgottenPasswordModalOpen: false,
			isPasswordReset: false,
			email: null,
		};
	},
	mounted() {
		history.replaceState(
			{ ...history.state, component: this.componentName },
			'',
			window.location.href,
		);
	},
	methods: {
		onModalClose() {
			useFormsStore(this.$store).api.storeValidationResponse('forgottenPassword', null);
		},
		submitLogin(data) {
			this.$emit('loginSubmit', { email: data.j_username, password: data.j_password });
		},
		async submitResetPassword(data) {
			try {
				const success = await useUserStore(this.$store).api.forgottenPassword(data.email);

				if (success) {
					this.isPasswordReset = true;
					this.email = data.email;
				}

				this.$emit('submit');
			} catch (error) {
				Logger.error(error);
			}
		},
	},
});
