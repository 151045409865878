import Vue from 'vue';
import { LcpOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { clientInitCmsContentStore, useCmsContentStore } from '~/@api/store/cmsContentApi';
import { clientInitRoutingStore, useRoutingStore } from '~/@api/store/routingApi';
import { useSearchStore } from '~/@api/store/searchApi';

export default Vue.extend({
	name: 'DefaultTemplate',
	components: {
		HydrateableClientOnly: () =>
			import(
				'@/node_modules/@osp/design-system/components/HydrateableClientOnly/HydrateableClientOnly'
			),
		WebsiteFooter: () => import('~/components/footer/footer'),
	},
	mixins: [LcpOptimizationMixin],
	props: {
		footer: { type: Boolean, default: true },
		header: { type: Boolean, default: true },
	},
	data() {
		return {
			isContentPage: false,
			isMyAccountPage: false,
			isLoginPage: false,
			isProductPage: false,
			is404Page: false,
			isSearchNoResults: false,
			isStoreDetailPage: false,
			isPasswordResetPage: false,
			isProductListingPage: false,
			headerComponentId: '',
		};
	},
	async fetch() {
		if (process.client) {
			await clientInitRoutingStore(this.$store).then(this.updatePageTypeFlags);
		} else {
			this.updatePageTypeFlags();
		}
	},
	watch: {
		'$store.state.references': {
			immediate: true,
			handler() {
				if (process.client) {
					clientInitCmsContentStore(this.$store).then(this.updateHeaderComponentId);
					return;
				}

				this.updateHeaderComponentId();
			},
		},
		'$route.fullPath': {
			handler() {
				this.updatePageTypes();
			},
		},
	},
	mounted() {
		// Initialize the Simple State Manager after the app has been mounted
		this.$nuxt.context.app.ssm.initialize();
	},
	methods: {
		updatePageTypes() {
			if (process.client) {
				clientInitRoutingStore(this.$store).then(this.updatePageTypeFlags);
				return;
			}

			this.updatePageTypeFlags();
		},
		updateHeaderComponentId() {
			this.headerComponentId = Object.keys(
				useCmsContentStore(this.$store).state.references || [],
			).find((key) => key.startsWith('OSPHeaderComponent__'));
		},
		updatePageTypeFlags() {
			// Page types by route path / meta / params
			this.isMyAccountPage = this.$route.path.includes('my-account');
			this.isLoginPage = this.$route.path.includes('login/index.html');
			this.isProductPage = this.$route.meta.spaType === 'product';
			this.isContentPage = this.$route.meta.spaType === 'content';
			this.isStoreDetailPage = !!this.$route.params.storeCode;
			this.isProductListingPage = this.$route.meta.spaType === 'category';
			this.isPasswordResetPage = this.$route.meta.spaType === 'password_reset';

			// Page types by routing spaData page
			this.is404Page =
				useRoutingStore(this.$store).state.spaData.datalayer?.page?.pageInfo?.pageID === 'notFound';
			this.isSearchNoResults =
				useRoutingStore(this.$store).state.spaData.datalayer?.page?.pageInfo?.pageID === 'search' &&
				useSearchStore(this.$store).state.response?.pagination?.totalCount === 0;
		},
	},
});
