import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Trusty } from '../../types/trusty';

// Model -------------------------------------------------------------------------------------------

export interface TrustiesModel {
	uid: string;
	items: Trusty[];
}

// Props -------------------------------------------------------------------------------------------

export const TrustiesProps: Props<TrustiesModel> = {
	uid: {
		type: String as PropType<TrustiesModel['uid']>,
		required: true,
	},
	items: {
		type: Array as PropType<TrustiesModel['items']>,
		required: true,
	},
};
