import { PropType } from 'vue';
import { Props } from '@/node_modules/@osp/design-system/types/core';

// Model -------------------------------------------------------------------------------------------

export interface HeaderModel {
	uid: string;
}

// Props -------------------------------------------------------------------------------------------

export const HeaderProps: Props<HeaderModel> = {
	uid: {
		type: String as PropType<HeaderModel['uid']>,
		required: true,
	},
};
