import isTouchDevice from '@/node_modules/@osp/design-system/assets/js/utilities/isTouchDevice';
import { Bool, Component, Mixins } from '@/app-utils/decorators';
import { useUxStore } from '~/@api/store/uxApi';
import { AbstractComponent } from '~/components/mixins/abstract-component';
import TouchRedirectModal from '~/components/molecules/touch-redirect-modal/touch-redirect-modal';

@Component({
	components: { TouchRedirectModal },
})
export default class Layout extends Mixins<AbstractComponent>(AbstractComponent) {
	public renderTouchRedirectModal = false;

	@Bool()
	public borderBottomHeader: boolean;

	@Bool()
	public borderBottomMain: boolean;

	@Bool()
	public loadingOverlay: boolean;

	mounted() {
		this.renderTouchRedirectModal =
			!this.$nuxt.context.from && this.BREAKPOINT.IS_DESKTOP && isTouchDevice();
	}

	get pageChangeInProgress() {
		return useUxStore(this.$store).state.pageSwitchInProgress;
	}

	get displayFooter() {
		return useUxStore(this.$store).state.displayFooter;
	}

	get componentStyle() {
		const style: string[] = [];

		style.push(this.$style.osp_master);

		if (this.borderBottomHeader) {
			style.push(this.$style.borderBottomHeader);
		}

		if (this.borderBottomMain) {
			style.push(this.$style.border_bottom_main);
		}

		return style;
	}
}
