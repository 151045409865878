import { Component, Vue, Watch } from '@/app-utils/decorators';
import Messagebox from '@/components/molecules/messagebox/messagebox.vue';
import { clientInitMessageboxStore, useMessageboxStore } from '~/@api/store/messageboxApi';
import { Messagebox as MessageboxType } from '~/@api/store.types';
import { importRunTask } from '~/app-utils/dynamic-imports';

@Component
export default class MessageboxWrapper extends Vue {
	protected globalMessages: MessageboxType[] = [];

	@Watch('$store.state.messagebox.messageboxes', { immediate: true })
	onMessageboxesChange() {
		if (process.client) {
			clientInitMessageboxStore(this.$store).then(this.updateGlobalMessages);
			return;
		}

		this.updateGlobalMessages();
	}

	mounted() {
		importRunTask().then(async () => {
			await clientInitMessageboxStore(this.$store);

			useMessageboxStore(this.$store)
				.state.messageboxes.filter((messagebox) => messagebox.key && !messagebox.global)
				.forEach((messagebox) => {
					if (document.querySelector(`#messagebox__${messagebox.key}`)) {
						// eslint-disable-next-line no-new
						new Vue({
							el: `#messagebox__${messagebox.key}`,
							render: (h) =>
								h(Messagebox, { attrs: { data: messagebox }, on: { dismiss: this.dismiss } }),
							store: this.$store,
						});
					}
				});
		});
	}

	updateGlobalMessages() {
		this.globalMessages = useMessageboxStore(this.$store).state.messageboxes.filter(
			(messagebox) => !messagebox.key || messagebox.global,
		);
	}

	dismiss(messagebox: MessageboxType) {
		useMessageboxStore(this.$store).api.dismiss(messagebox);
	}
}
