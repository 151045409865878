import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { helpers, maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { scrollIntoView } from '../../assets/js/utilities/scroll';
import {
	FieldValidations,
	FormError,
	FormField,
	FormFieldType,
	FormFieldValidations,
	FormValues,
	TypeAheadData,
	ValidationsValues,
} from '../../types/forms';
import { GenericFormProps } from './GenericForm.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'GenericForm',
	mixins: [validationMixin],
	props: GenericFormProps,
	data() {
		return {
			values: {},
			typeAhead: {},
			timeout: null as number | null,
		};
	},
	computed: {
		fields(): FormField[] {
			return this.getFilteredFields();
		},
		errorFields(): string[] {
			return [
				...this.fields
					.filter((field) => this.$v?.values?.[field.id]?.$error)
					.map((field) => field.id),
				...this.backendErrorFields,
			];
		},
		hasError(): boolean {
			return !!this.errorFields?.length;
		},
		backendErrorFields(): string[] {
			return this.backendValidationErrors
				.map((error: FormError) => error.field)
				.filter((field): field is string => field !== undefined);
		},
		backendValidationErrors(): FormError[] {
			return this.formData?.validationResponse?.errors || [];
		},
		isFormAutocompleteOn(): string | undefined {
			if (this.config.autocomplete) {
				return 'on';
			}

			if (this.config.autocomplete === false) {
				return 'off';
			}

			return undefined;
		},
	},
	watch: {
		config: {
			immediate: true,
			handler(): void {
				this.config?.fields?.forEach((field) =>
					this.setField(field.id, this.getDefaultValue(field) as string),
				);
			},
		},
		backendValidationErrors(): void {
			this.scrollToFirstInvalidField();
		},
	},
	validations(): { values: ValidationsValues } {
		const values: ValidationsValues = {};

		this.fields.forEach((field) => {
			values[field.id] = this.getValidations(field);
		});

		return {
			values,
		};
	},
	methods: {
		getValidations(field: FormField): FormFieldValidations {
			if (this.isValidationDisabled || !field?.validations) {
				return {} as FormFieldValidations;
			}

			const fieldValidations: FieldValidations = {};

			Object.keys(field.validations).forEach((type) => {
				const validation = (field.validations as any)[type];

				if (type === 'required' && validation.required) {
					if (field.type === FormFieldType.CHECKBOX) {
						fieldValidations[type] = sameAs(() => true);
					} else {
						fieldValidations[type] = required;
					}
				}

				if (type === 'maxLength') {
					fieldValidations[type] = maxLength(validation.maxLength);
				}

				if (type === 'minLength') {
					fieldValidations[type] = minLength(validation.minLength);
				}

				if (type === 'regex') {
					fieldValidations[type] = helpers.regex(type, RegExp('^' + validation.regexPattern + '$'));
				}
			});

			return fieldValidations;
		},
		getFilteredFields(): FormField[] {
			return (this.config?.fields || []).filter((field) => {
				const visible = this.fieldsFilter?.(field, this.values || {});

				// Clear field value if it got invisible now
				if (!visible && (this.values as FormValues)?.[field.id]) {
					this.$delete(this.values, field.id);
				}

				return visible;
			});
		},
		setField(fieldId: string, value: string, validate = false): void {
			this.$set(this.values, fieldId, value);

			if (validate) {
				this.$v?.values?.[fieldId]?.$touch();
			}
		},
		getDefaultValue(field: FormField): number | string | boolean | undefined {
			return (
				this.defaultValues?.[field.id] ||
				field.value ||
				field.buttons?.find((button) => button.checked)?.value ||
				(field.type === FormFieldType.SELECT ? field?.buttons?.[0] : {})?.value
			);
		},
		async handleInput(field: FormField, event: InputEvent): Promise<void> {
			if (this.backendErrorFields.includes(field.id)) {
				this.$emit('clear-backend-error', field.id);
			}

			const inputHandler = this.formData?.inputHandler?.[field.id] || null;

			if (inputHandler?.typeAhead) {
				this.showTypeAhead(
					field.id,
					await inputHandler.typeAhead(this.$v.values.$model),
					!!event.data,
				);
			} else {
				this.clearTypeAhead();
			}
		},
		setFieldValue(fieldId: string, value: string): void {
			this.$set(this.values, fieldId, value);
		},
		showTypeAhead(fieldId: string, datas: TypeAheadData[], newChar: boolean): void {
			if (!datas.length) {
				this.clearTypeAhead();
			} else if (datas.length === 1 && newChar) {
				Object.entries(datas[0].model).forEach((entry) => this.setFieldValue(entry[0], entry[1]));
				this.clearTypeAhead();
			} else {
				this.typeAhead = {
					data: datas.map((data) => ({
						label: data.label,
						listener: () => {
							Object.entries(data.model).forEach((entry) => this.setFieldValue(entry[0], entry[1]));
							this.clearTypeAhead();
						},
					})),
					fieldId,
				};
			}
		},
		clearTypeAhead(): void {
			this.typeAhead = {};
		},
		touchField(field: FormField): void {
			this.$v?.values?.[field.id]?.$touch?.();

			this.handleTimeout();
		},
		getInputType(field: FormField): 'tel' | 'number' | 'date' | 'text' | 'email' | 'password' {
			switch (field.type) {
				case 'TEXTFIELD':
					if (field.inputMode === 'TEL') {
						return 'tel';
					}

					if (field.inputMode === 'NUMERIC') {
						return 'number';
					}

					if (field.inputMode === 'DATE') {
						return 'date';
					}

					return 'text';
				case 'EMAIL':
					return 'email';
				case 'PASSWORD':
					return 'password';
				default:
					return 'text';
			}
		},
		getRadioButtons(field: FormField) {
			return (field.buttons || []).map((button) => ({
				...button,
				autocomplete: field.autocomplete,
				checked: button.value === this.getDefaultValue(field),
			}));
		},
		getAutocompleteValue(field: FormField): 'on' | 'off' | undefined {
			if (field.autocomplete) {
				return 'on';
			}

			if (field.autocomplete === false) {
				return 'off';
			}

			return field.type === 'PASSWORD' ? 'off' : undefined;
		},
		handleSubmit(): void {
			this.$v.$touch();

			if (!this.hasError) {
				this.$emit('submit', this.values);
			} else {
				setSafeTimeout(() => this.scrollToFirstInvalidField(), 100);
			}
		},
		async scrollToFirstInvalidField(): Promise<void> {
			await this.$nextTick();

			const invalidFormElement = this.$el.querySelector('.form-field--has-error');
			const containerElement = invalidFormElement?.closest('[data-name="modal-content"]');

			scrollIntoView(invalidFormElement, 'smooth', 'middle', containerElement);
		},
		resetForm(): void {
			this.config.fields?.forEach((field) =>
				this.$set(this.values, field.id, this.getDefaultValue(field)),
			);
			this.$v.$reset();
			this.clearTimeout();
		},
		handleTimeout(): void {
			this.clearTimeout();

			if (this.resetTimeout) {
				this.timeout = setSafeTimeout(this.resetForm, this.resetTimeout);
			}
		},
		clearTimeout(): void {
			if (this.timeout) {
				clearTimeout(this.timeout);

				this.timeout = null;
			}
		},
	},
});
